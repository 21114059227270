import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './reducers/rootReducer';
import { BrowserRouter,HashRouter, Switch } from 'react-router-dom';
// import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

const logger = store => {
    return next => {
        return action => {
            console.log('[middleware] dispatching', action)
            const result =  next(action)
            console.log('[middleware] next state', store.getState())
            return result
        }
    }
}
// const sagaMiddleware = createSagaMiddleware();
// sagaMiddleware.run();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

const app = (
    <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
