import React, { PureComponent } from 'react';
import Home from './home';
import W3hexschool from './w3hexschool';
import MaskMap from './maskMap';
import NotFound from './notFound';
import Header from './header';
import { Route, Link, Switch, Router, Redirect } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import './App.scss';
// const { Header } = Layout;
class App extends PureComponent {
  render() {
    return(
      <div className="container">
        <Header />
        {/* <Header>
          <div className="logo">
            <Link to="/"><h1 className='logo-title'>YY's tools</h1></Link>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['/']}
            style={{ lineHeight: '64px' }}
            selectedKeys={[this.props.location.pathname]}
          >
            <Menu.Item key="/w3hexschool">
              <Link to="/w3hexschool">鼠年全馬鐵人挑戰</Link>
            </Menu.Item>
            <Menu.Item key="/mask">
              <Link to="/mask">口罩查詢</Link>
            </Menu.Item>
          </Menu>
        </Header> */}
        {/* https://github.com/facebook/create-react-app/issues/1765#issuecomment-285114194 */}
        {/* https://stackoverflow.com/questions/46056414/getting-404-for-links-with-create-react-app-deployed-to-github-pages */}
        <Switch>
        
          <Route exact path="/" exact component={Home} />
          <Route path={process.env.PUBLIC_URL + '/w3hexschool'} component={W3hexschool} />
          <Route path="/mask" component={MaskMap} />
          {/* <Route path="*" component={NotFound} /> */}
          {/* <Redirect to="/" /> */}
          {/* <Route component={PageNotFound} /> */}
        </Switch>
      </div>
    );
  }
}
export default App;
