import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getW3Hex2020Data } from '../actions/actions';
import { Button, Table, Badge, Menu, Dropdown, Icon } from 'antd';
import { initGA, logPageView } from '../utils/analytics';
class W3hexschool extends PureComponent {

  state = {
    filteredInfo: null,
    sortedInfo: null,
  };

  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
    this.props.getW3Hex2020Data();
  }

  expandedRowRender = (record, index) => {
    // title url
    const bloggerData = this.props.w3HexData[index].blogList;
    const columns = [
      { title: '標題', dataIndex: 'title', key: 'title', ellipsis: true },
      {
        title: '狀態',
        key: 'url',
        render: () => (
          <span>
            <Badge status="success" />
            Finished
          </span>
        ),
      }
    ];

    const data = [];
    for (let i = 0; i < bloggerData.length; i++) {
      data.push({
        key: i,
        title: <a href={bloggerData[i].url}>{bloggerData[i].title}</a>
      });
    }
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  }
  
  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      { title: '名字', dataIndex: 'name', key: 'name', ellipsis: true },
      { 
        title: '日期', 
        dataIndex: 'createdDay', 
        key: 'createdDay',
        sorter: (a, b) => {
          const aRawData = a.createdDay.split(' ');
          const bRawData = b.createdDay.split(' ');
          const aDate = new Date(aRawData[0]);
          const bDate = new Date(bRawData[0]);
          const aTime = aRawData[2].split(':');
          const bTime = bRawData[2].split(':');
          aDate.setHours(aTime[0]);
          bDate.setHours(bTime[0]);
          aDate.setMinutes(aTime[1]);
          bDate.setMinutes(bTime[1]);
          aDate.setSeconds(aTime[2]);
          bDate.setSeconds(bTime[2]);
          if(aDate===bDate) {
            return 0;
          } else if(aDate>bDate) {
            return 1;
          } else if(aDate<bDate) {
            return -1;
          }
        },
        sortOrder: sortedInfo.columnKey === 'createdDay' && sortedInfo.order,
      }
    ];

    const data = [];
    const w3HexData = this.props.w3HexData;
    for (let i = 0; i < w3HexData.length; i++) {
      data.push({
        key: i,
        name: <a href={w3HexData[i].blogUrl}>{String(w3HexData[i].name).length!==0 ? w3HexData[i].name : '[匿名]'}</a>,
        createdDay: w3HexData[i].updateTime,
      });
    }

    return(
      <div>
        <h1 style={{ textAlign: 'center' }}>鼠年全馬鐵人挑戰</h1>
        <Table
          className="components-table-demo-nested"
          columns={columns}
          expandedRowRender={this.expandedRowRender}
          dataSource={data}
          loading={this.props.loading}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    w3HexData: state.w3HexData,
    loading: state.status.w3HexLoading
  };
};

const mapDispatchToProps = dispatch => ({
    getW3Hex2020Data: () => dispatch(getW3Hex2020Data())
    // appendOption: () => dispatch(actionCreators.appendOption()),
});

export default connect(mapStateToProps, mapDispatchToProps)(W3hexschool);
