import React, { PureComponent } from 'react';
import Home from '../home';
import W3hexschool from '../w3hexschool';
import MaskMap from '../maskMap';
import { Router, Route, Link, Switch } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import './header.scss';
// const { Header } = Layout;
class Header extends PureComponent {

  render() {
    return(
        <div className='header'>
        <Layout>
          {/* <div className="logo">
            <Link to="/"><h1 className='logo-title'>YY's tools</h1></Link>
          </div> */}
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['/']}
            style={{ lineHeight: '64px' }}
            selectedKeys={['/']}
            // selectedKeys={[this.props.history.location.pathname]}
          >
            <Menu.Item key="/w3hexschool">
              <Link to="/w3hexschool">鼠年全馬鐵人挑戰</Link>
            </Menu.Item>
            <Menu.Item key="/mask">
              <Link to="/mask">口罩查詢</Link>
            </Menu.Item>
          </Menu>
        </Layout>
        </div>
    );
  }
}

export default Header;
