import React, { PureComponent } from 'react';
import { initGA, logPageView } from '../utils/analytics';
class Home extends PureComponent {
  componentDidMount () {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }
  render() {
    return(
        <h2>歡迎來參觀YY測試區，有興趣的話也可以來<a href="https://blog.yyisyou.tw" target="_blank">參觀我的Blog。</a></h2>
    );
  }
}
export default Home;
