// import * as actionTypes from './actionTypes';
// import * as address from '../../config/address';

// const ERROR_MESSAGE_FROM_SERVER = '伺服器取得資料失敗';

//get data from w3hex
export const getW3Hex2020Data = () => {
    return dispatch => {
        fetch('https://raw.githubusercontent.com/hexschool/w3hexschool-API/master/data.json', {
            method: 'GET'
        }).then((response) => {
            if(!response.ok) {
                console.log('fail');
            } else {
                return response.json();
            }
        })
        .then((data) => {
                return dispatch({
                    type: 'REQUEST_W3HEX2020_DATA',
                    data: data
                })
            }
        )
    }
}

//get data from w3hex
export const getMaskData = () => {
    return dispatch => {
        fetch('https://raw.githubusercontent.com/kiang/pharmacies/master/json/points.json', {
            method: 'GET'
        }).then((response) => {
            if(!response.ok) {
                console.log('fail');
            } else {
                return response.json();
            }
        })
        .then((data) => {
                return dispatch({
                    type: 'REQUEST_MASK_DATA',
                    data: data
                })
            }
        )
    }
}

// export const deleteOption = (id) => {

//     return dispatch => {
//         fetch( address.server + '/test/DEL/' + id, {
//             method: 'POST',
//         })
//         .then((response) => {

//             if (!response.ok) {
//                 throw new Error(response.statusText)
//             }

//             return response.json()
//         })
//         .then((status) => {
//             //-1不存在     0未完成     1已刪除
//             switch(status) {
//                 case DELETE_OPTION.deleted: {
//                     // alert('已刪除這個項目')
//                     return dispatch(setDeleteOption(id))
//                 }
//                 case DELETE_OPTION.notExist: {
//                     alert('這個項目不存在')
//                     return dispatch(setERROR())
//                 }
//                 case DELETE_OPTION.unfinished: {
//                     alert('未完成的項目無法刪除')
//                     return dispatch(setERROR())
//                 }
//                 default: {
//                     return dispatch(setERROR())
//                 }
//             }
//         })
//         .catch((error) => {
//             alert(ERROR_MESSAGE_FROM_SERVER)
//             console.error(error)
//         })
//     }

// }
