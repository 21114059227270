import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getMaskData } from '../actions/actions';
import { Button, Table, Badge, Menu, Dropdown, Icon } from 'antd';
import { initGA, logPageView } from '../utils/analytics';
class maskMap extends PureComponent {

  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
    this.props.getMaskData();

  }

  expandedRowRender = (record, index) => {
    // title url
    const bloggerData = this.props.w3HexData[index].blogList;
    // console.log(bloggerData);
    const columns = [
      { title: '標題', dataIndex: 'title', key: 'title' },
      {
        title: '狀態',
        key: 'url',
        render: () => (
          <span>
            <Badge status="success" />
            Finished
          </span>
        ),
      }
    ];

    const data = [];
    for (let i = 0; i < bloggerData.length; i++) {
      data.push({
        key: i,
        title: <a href={bloggerData[i].url}>{bloggerData[i].title}</a>
      });
    }
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
  
  render() {
    const columns = [
      { title: '名字', dataIndex: 'name', key: 'name' },
      { title: '成人口罩', dataIndex: 'adultMask', key: 'adultMask' },
      { title: '兒童口罩', dataIndex: 'childMask', key: 'childMask' },
    ];

    const data = [];
    const maskData = this.props.maskData;
    // console.log('maskData->',maskData)
    for (let i = 0; i < maskData.length; i++) {
      data.push({
        key: i,
        name: maskData[i].properties.name,
        adultMask: maskData[i].properties.mask_adult,
        childMask: maskData[i].properties.mask_child,
      });
    }

    // console.log('data->', data);

    return(
      <div>
        <h1>施工中</h1>
        <h2>其他可參考我寫的<a href="https://blog.yyisyou.tw/1db9b17c/" target="_blank">LINE口罩地圖聊天機器人範例</a></h2>
        {/* <p>test</p>
        <Table
          className="components-table-demo-nested"
          columns={columns}
          expandedRowRender={this.expandedRowRender}
          dataSource={data}
        /> */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    maskData: state.maskData
  };
};

const mapDispatchToProps = dispatch => ({
    getMaskData: () => dispatch(getMaskData())
    // appendOption: () => dispatch(actionCreators.appendOption()),
});

export default connect(mapStateToProps, mapDispatchToProps)(maskMap);
